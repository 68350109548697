<template>
    <base-content>
        <template #button>
            <a-form-item label="统计区域" style="margin:0">
                <a-tree-select v-model:value="storage_id" :treeData="storage_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" style="width:400px" treeDefaultExpandAll placeholder="请选择储存区" @change="getData" />
            </a-form-item>
        </template>
        <a-table :columns="table_columns" :data-source="table_data" rowKey="material_id" :pagination="false" bordered :loading="table_loading">
            <template #operation="scoped">
                <a-button @click="getDetail(scoped.record.material_id)">查看详情</a-button>
            </template>
        </a-table>
        <a-pagination style="display:flex;justify-content:center;margin-top:8px" show-size-changer v-model:current="page" v-model:pageSize="perpage" :total="data_count" @change="getData" @showSizeChange="getData" :pageSizeOptions="['10', '15', '20', '40']" v-if="data_count" />
        <a-drawer :title="drawer_title" placement="right" v-model:visible="drawer_visible" :get-container="false" :wrap-style="{ position: 'absolute' }" width="75%" :bodyStyle="{ padding: '10px' }">
            <a-divider style="margin:10px 0px">物料详情</a-divider>
            <a-row v-if="material_detail">
                <a-col :span="6">
                    <a-form-item label="物料编码" style="margin:0px">{{ drawer_material_detail.prefix }}-{{ _10to32(drawer_material_detail.suffix) }}</a-form-item>
                </a-col>
                <a-col :span="7">
                    <a-form-item label="物料名称" style="margin:0px">{{ drawer_material_detail.name }}</a-form-item>
                </a-col>
                <a-col :span="7">
                    <a-form-item label="所在分类" style="margin:0px">{{ drawer_material_detail.category_name }}</a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item label="计量单位" style="margin:0px">{{ drawer_material_detail.unit }}</a-form-item>
                </a-col>
            </a-row>
            <a-row v-if="material_detail">
                <a-col :span="6">
                    <a-form-item label="标签" style="margin:0px">
                        <a-tag v-for="item in drawer_material_detail.tags" :key="item">{{ item }}</a-tag>
                    </a-form-item>
                </a-col>
                <a-col :span="9">
                    <a-form-item label="材质" style="margin:0px">{{ drawer_material_detail.texture }}</a-form-item>
                </a-col>
                <a-col :span="9">
                    <a-form-item label="规格" style="margin:0px">{{ drawer_material_detail.specification }}</a-form-item>
                </a-col>
            </a-row>
            <a-row v-if="material_detail">
                <a-col :span="10">
                    <a-form-item label="备注" style="margin:0px">{{ drawer_material_detail.remark }}</a-form-item>
                </a-col>
                <a-col :span="14">
                    <a-form-item label="图片" style="margin:0px;" :wrapperCol="{ span: 20 }">
                        <ImageUpload v-model:value="drawer_material_detail.images" style="width:100%" disabled />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-alert v-else message="无权限" description="你没有查看物料详情的权限，如有需要，请联系管理员" type="info" show-icon />
            <a-divider style="margin:10px 0px">库存详情</a-divider>
            <a-table :columns="drawer_table_columns" :data-source="drawer_table_data" rowKey="material_key" :pagination="false" bordered :loading="table_loading" v-if="inventory_detail">
                <template #operation="scoped">
                    <a-button @click="getChangeDetail(scoped.record.material_key)">查看详情</a-button>
                </template>
            </a-table>
            <a-alert v-else message="无权限" description="你没有查看物料库存详情的权限，如有需要，请联系管理员" type="info" show-icon />
        </a-drawer>
        <a-drawer :title="drawer_drawer_title" placement="right" v-model:visible="drawer_drawer_visible" :get-container="false" :wrap-style="{ position: 'absolute' }" width="50%" :bodyStyle="{ padding: '10px' }">
            <!-- <a-list :data="drawer_drawer_list_data"></a-list> -->
            <a-table :columns="drawer_drawer_table_columns" :data-source="drawer_drawer_table_data" rowKey="time" :pagination="false" bordered :loading="table_loading">
                <!-- <template #operation="scoped">
                    <a-button @click="getChangeDetail(scoped.record.material_key)">查看详情</a-button>
                </template> -->
            </a-table>
        </a-drawer>
    </base-content>
</template>

<script>
import ImageUpload from '@/components/ImageUpload';
export default {
    components: { ImageUpload },
    data() {
        return {
            storage_tree: [],
            // 当前所选储存区
            storage_id: 0,
            page: 1,
            perpage: 10,
            data_count: 0,

            table_data: [],
            table_columns: [
                { title: '物料编码', dataIndex: 'material_code', align: 'center', width: '100px' },
                { title: '物料名称', dataIndex: 'name' },
                { title: '库存概括', dataIndex: 'inventory_brief' },
                { title: '操作', slots: { customRender: 'operation' }, align: 'center', width: '120px' },
            ],
            table_loading: false,
            // 当前所选物料id，点击查看详情的
            material_id: '',
            // 三种权限，分别对应物料详情查看（抽屉打开后上半部分）、库存详情查看（抽屉下半）、库存变化详情查看（抽屉内的抽屉）
            material_detail: false,
            inventory_detail: false,
            inventory_change_detail: false,
            // 展示物料详情和库存详情的抽屉
            drawer_visible: false,
            drawer_title: '',
            // 抽屉内的表格数据及字段
            drawer_table_data: [],
            drawer_table_columns: [
                { title: '供应商', dataIndex: 'supplier_name' },
                { title: '批号', dataIndex: 'batch_number' },
                { title: '储存区', dataIndex: 'storage_name' },
                { title: '库存数量', dataIndex: 'count' },
                { title: '操作', slots: { customRender: 'operation' }, align: 'center', width: '120px' },
            ],
            //抽屉内的物料详情数据
            drawer_material_detail: {},
            // 抽屉内的抽屉，展示库存变化记录
            drawer_drawer_visible: false,
            drawer_drawer_title: '',
            // 抽屉内抽屉的列表数据
            drawer_drawer_table_data: [],
            drawer_drawer_table_columns: [
                { title: '出/入库流水号', dataIndex: 'serial' },
                { title: '类型', dataIndex: 'type' },
                { title: '变化数量', dataIndex: 'count' },
                { title: '时间', dataIndex: 'time' },
            ],
        };
    },
    mounted() {
        this.$api('getStorageTree').then((res) => {
            this.storage_tree = res.storage_tree;
        });
        this.$api('getStorageList', { parent_id: -1 }).then((res) => {
            this.storage_list = res.storage_list;
        });
        this.$api('getTotalSupplierList').then((res) => {
            this.supplier_list = res.supplier_list;
        });
        this.$api('getCategoryList', { parent_id: -1 }).then((res) => {
            this.category_list = res.category_list;
        });
        this.getData();
    },
    methods: {
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                console.error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
                return;
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('0000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                console.error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
                return;
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    console.error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                    return;
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        async getData() {
            this.table_loading = true;
            let res = await this.$api('getInventoryList', { storage_id: this.storage_id, page: this.page, perpage: this.perpage });
            res.inventory_list.forEach((item) => {
                item.material_code = item.prefix + '-' + this._10to32(item.suffix);
                item.inventory_brief = `包含${item.kind_count}种批次，来自${item.supplier_count}家供应商，分别储存至${item.storage_count}个储存区，共计${item.total_count}${item.unit}`;
            });
            this.data_count = res.data_count;
            this.table_data = res.inventory_list;
            this.table_loading = false;
        },
        getDetail(material_id) {
            this.drawer_visible = true;
            let storage_name = '';
            this.storage_list.forEach((storage) => {
                if (storage.id == this.storage_id) storage_name = storage.name;
            });
            let material_name = '';
            this.table_data.forEach((item) => {
                if (item.material_id == material_id) material_name = item.name;
            });
            this.drawer_title = `【${storage_name} / ${material_name}】 物料详情`;
            this.material_detail = false;
            this.$api('getMaterialDetail', { material_id }).then((res) => {
                this.material_detail = true;
                this.category_list.forEach((item) => {
                    if (item.id == res.material_detail.category_id) res.material_detail.category_name = item.name;
                });
                res.material_detail.images = JSON.parse(res.material_detail.images);
                this.drawer_material_detail = res.material_detail;
            });
            this.inventory_detail = false;
            this.$api('getMaterialInventoryDetail', { storage_id: this.storage_id, material_id }).then((res) => {
                this.inventory_detail = true;
                res.inventory_detail.forEach((res) => {
                    this.supplier_list.forEach((item) => {
                        if (item.supplier_id == res.supplier_id) res.supplier_name = item.name;
                    });
                    this.storage_list.forEach((item) => {
                        if (item.id == res.storage_id) res.storage_name = item.name;
                    });
                });
                this.drawer_table_data = res.inventory_detail;
            });
        },
        getChangeDetail(material_key) {
            this.drawer_drawer_visible = true;
            this.$api('getMaterialInventoryChangeDetail', { material_key }).then((res) => {
                this.drawer_drawer_table_data = res.inventory_change_detail;
            });
            this.drawer_table_data.forEach((item) => {
                if (item.material_key == material_key) this.drawer_drawer_title = `【${item.supplier_name} / ${item.batch_number} / ${item.storage_name}】 物料库存变化详情`;
            });
        },
    },
};
</script>

<style></style>
